<template>
  <main class="kb-main" id="kb-myclass-notice">
    <lxp-main-header :show-title="true" :show-back="true" :title="noticeTitle">
      <template v-slot:inner>
        <div class="header-column">
          <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
            <button v-if="fileItems.length > 0" class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list">
                  <li v-for="(item, idx) in fileItems" :key="idx" class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" :title="item.fileNm"  @click.stop="downloadAtch(item)">
                      <span class="dropdown-option-text">{{item.fileNm}}</span>
                      <span class="download-mb">{{ convertToStorageBytes(item.fileSz)}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <h3 class="title">{{items.title}}</h3>
          <p class="subtitle">{{ getDateFormat('yyyy.MM.dd hh:mm', items.regDt) }} 등록</p>
        </div>
        <div class="view-body min-component">
          <div class="view-content" v-html="items.cn" />
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {getItem, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {
  ACT_DOWNLOAD_BOARD_ATCH,
  ACT_GET_LRN_BOARD_FILE_ATCH_LIST
} from "@/store/modules/board/board";
import {convertToStorageBytes} from "@/assets/js/util";
import {ACT_BOARD_GET_NTC} from "@/store/modules/live/live";

export default {
  name: 'LiveNoticeView',
  components: {LxpMainHeader},
  setup(){
    const noticeTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);

    const lrnPostSn = computed(() => route.params.lrnPostSn);

    const getLrnBoardNotice = () => {
      store.dispatch(`live/${ACT_BOARD_GET_NTC}`,
          lrnPostSn.value,
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          getLrnBoardNoticeFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getLrnBoardNotice();

    const getLrnBoardNoticeFileAtchList = () => {
      store.dispatch(`board/${ACT_GET_LRN_BOARD_FILE_ATCH_LIST}`, {
        lrnPostSn: lrnPostSn.value,
        stt: '00'
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const pageViewCnt = async () => {
      if (lrnPostSn.value > 0) {
        console.log()
        // await store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {lrnPostSn: lrnPostSn.value});
      }
    };

    const downloadAtch = (atch) => {
      store.dispatch(`board/${ACT_DOWNLOAD_BOARD_ATCH}`, { atchFileSn: atch.atchFileSn, fileName: atch.fileNm });
    };

    onMounted(() => {
      pageViewCnt();
    });

    watch(() => route.params.ntcSn, ()=>{
      getLrnBoardNotice();
    });

    return {
      noticeTitle,
      items,
      fileItems,
      showAttachments,
      getDateFormat,
      downloadAtch,
      convertToStorageBytes,
    }

  }
}
</script>
<style type="text/css">
.view-content p{
  font: unset;
}
</style>